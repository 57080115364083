/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }
  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);
    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);
      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }
    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }
  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }
  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }
  .css-12jo7m5 {
    color: $heading-color;
  }
  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

.icon-edit-tab {
  line-height: 1.2;
  padding: 0 5px;
  margin-left: 5px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.45);

  &:hover {
    font-weight: 500;
    color: #111 !important;
  }
}
.ant-tabs-tab-remove {
  margin-left: 3px !important;
}

.execution-sidebar {
  position: relative;
  background-color: var(--#{$prefix}card-bg-custom);
  height: calc(100vh - 300px);
  overflow-y: auto;

  @media (min-width: 992px) {
    width: 50%;
  }

  @media (max-width: 991.98px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    max-width: 100%;
    z-index: 1003;
    box-shadow: $box-shadow-lg;
    transform: translateX(-100%);
    visibility: hidden;
    height: 100vh;

    &.menubar-show {
      visibility: visible;
      transform: none;
    }
  }
}

.execution-action {
  padding: 5px;
  margin-bottom: 18px;
  position: relative;
  text-align: center;
  z-index: 22;
  background: #fff;
  cursor: pointer;
}
.execution-action-after {
  &::after {
    content: "";
    border-left: 2px dashed var(--vz-border-color);
    position: absolute;
    height: 21px;
    left: 50%;
    top: 30px;
    z-index: 1;
  }
}
.execution-action-border {
  border: 1px solid #cccc;
  border-radius: 3px;
}

.nav-tabs::-webkit-scrollbar {
  height: 5px;
  border-radius: 8px;
  cursor: pointer;
}

/* Track */
.nav-tabs::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.nav-tabs::-webkit-scrollbar-thumb {
  background: #fff;
}

.nav-tabs:hover {
  &::-webkit-scrollbar-thumb {
    background: #ccccccde;
  }
}

/* Handle on hover */
.nav-tabs::-webkit-scrollbar-thumb:hover {
  background: #ccccccde;
}

.icon-copy {
  position: absolute;
  font-size: 21px;
  margin-left: 8px;
  display: none;
  z-index: 5;
}
.hover-title-tree {
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 22px;
    z-index: 1;
  }
  &:hover {
    & .icon-copy {
      display: inline-block;
    }
  }
}

.ant-mention-editor-wrapper .public-DraftEditor-content div > span:not([data-offset-key]) {
  background-color: red;
}

.fullscreen {
  height: 100%;
  position: relative;
  background: #fff;
}
.icon-fullscreen {
  position: absolute;
  z-index: 222;
  cursor: pointer;
  // top: 5px;
  // left: 10px;
}
.ant-table-cell {
  padding: 12px 9px !important;
}

.array-item-toolbox > .btn-group {
  display: block !important;
}
.ant-pagination-item {
  min-width: 38px !important;
}
.ant-pagination-item > a {
  border: 1px solid #cfcfcf73;
  background-color: #fff;
  color: var(--vz-link-color);
  font-weight: 500;
  border-radius: 0.25rem !important;
}
.ant-pagination-item-active > a {
  background-color: #f7b84b;
  color: #fff !important;
  border-color: #f7b84b;
}
.ant-pagination .ant-pagination-item-active {
  border-color: #fff !important;
}
.ant-pagination-next > button,
.ant-pagination-prev > button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  border: 1px solid #cfcfcf73 !important;
  width: 38px !important;
  border-radius: 0.25rem !important;
  background-color: #fff !important;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

.skeleton-box {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    min-height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: linear-gradient(to right, #dddddd, #ffffff 50%, #dddddd);
    // animation: skeletonLoading 2s infinite alternate;

    animation: skeleton-loading 1s linear infinite alternate;
  }

  &::before {
    background-color: #dddddd;
    content: "";
    min-height: 1em;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 8%, 85%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
@keyframes skeletonLoading {
  from {
    left: -50%;
  }
  to {
    left: 50%;
  }
}

.ant-pagination-options-size-changer {
  display: none !important;
}
