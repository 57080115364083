.table-inform {
  border-collapse: separate;

  :global {
    .ant-table-content {
      table {
        display: table;
      }
    }

    .ant-table table {
    }
  }
}